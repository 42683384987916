/* .ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  white-space: normal;
  word-break: break-all;
} */

.custom-timeline .ant-timeline-item-tail {
  border-left: 3px dotted #abacab;
}

.yellow-button {
  background-color: rgb(255, 171, 0);
  color: #fff;
  border-color: rgb(255, 171, 0);
}

.yellow-button:hover {
  background-color: rgb(255, 196, 0) !important;
  color: #fff !important;
  border-color: rgb(255, 196, 0);
}

.green-button {
  background-color: rgb(27, 94, 32);
  color: #fff;
  border-color: rgb(27, 94, 32);
}

.green-button:hover {
  background-color: rgb(46, 125, 50) !important;
  color: #fff !important;
  border-color: rgb(46, 125, 50);
}

.red-button {
  background-color: rgb(213, 0, 0);
  color: #fff;
  border-color: rgb(213, 0, 0);
}

.red-button:hover {
  background-color: rgb(255, 23, 68) !important;
  color: #fff !important;
  border-color: rgb(255, 23, 68);
}