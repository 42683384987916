html {
  scroll-behavior: smooth;
}

.ant-menu-item {
  padding-left: 18px !important;
}

.ant-menu-item-selected {
  padding-left: 18px !important;
}

.ant-menu-item-selected .sidebar-text {
  font-weight: 500;
  color: white;
}

.ant-btn-primary {
  box-shadow: none;
}

.sidebar-text {
  margin-left: 10px;
  font-weight: 500;
  color: gray;
}

.custom-side-bar {
  height: '100vh';
  left: 0px !important;
  top: 70px !important;
  bottom: 0 !important;
  background: #f9f9f9 !important;
  position: fixed !important;
  overflow: auto;
}

.custom-content {
  position: fixed !important;
  top: 60px !important;
  left: 250px;
  right: 0px !important;
  bottom: 0px !important;
  padding: 50px 30px 24px 30px !important;
  margin: 0px !important;
  overflow: scroll !important;
  background: white !important;
}

.mapboxgl-popup-content {
  border-radius: 10px !important;
}
.mapboxgl-popup-close-button {
  background-color: transparent !important;
  font-size: 20px;
}
.mapboxgl-popup-close-button:focus {
  outline: none;
}

@media screen and (max-width: 768px) {
  .logo-center {
    text-align: center;
  }

  .custom-side-bar {
    left: 0 !important;
    right: 0 !important;
    background-color: #c2002f !important;
  }

  .custom-content {
    left: 0 !important;
  }
}

.custom-marker {
  transition: transform 0.3s ease-in-out !important;
}

.side-bar-header{
  display: flex;
  align-items: center;
  padding-left: 15px;
  color: black;
  font-size: 12px;
  font-weight: 700;
  background-color: lightgray;
  height: 30px;
}
.ant-modal-content {
  border-radius:20px !important;
}

.ant-table-thead .ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters {
  background-color: #fafafa !important;
}

.ant-table-thead > tr:nth-child(2) > th {
  /* background-color: #f8f8f8; */
  height: 30px;
  line-height: 30px;
  padding: 0px 15px
}

.ant-table-thead > tr > th {
  height: 30px;
  line-height: 15px;
  padding: 7px;
}

.table-row-gray {
  background-color: #f1f1f1;
}


.table-row-white{
  background-color: white;
}

.table-row-gray .ant-table-cell {
  background-color: #f1f1f1;
}

.table-row-gray .ant-table-cell.ant-table-column-sort.ant-table-cell {
  background-color: #f1f1f1;
}
.table-row-white .ant-table-cell.ant-table-column-sort.ant-table-cell {
  background-color: white;
}



.step-full-width-title .ant-steps-item-title {
  width: 100% !important;
}
.card-shadow{
  /* EDEDED */

  box-shadow: 0px 4px 20px #ead7d7 !important;
  border-radius: 15px;
}